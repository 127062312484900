<template>
  <dash-page  :title="$t('Profile')"
              :mobile-title="$t('Profile')"
              :subtitle="$t('Settings')"
              :no-side="SM"
              icon="mdi-account-cog"
  >
    <template #header_action>
      <v-btn @click="saveChanges"
             dark
             :color="wsATTENTION"  class="noCaps">
        {{ $t('Save') }}
      </v-btn>
    </template>
    <template #header_mobile_action_side>
      <v-btn @click="saveChanges"
             text
             :color="wsATTENTION"  class="noCaps">
        {{ $t('Save') }}
      </v-btn>
    </template>
    <template #default>
      <v-sheet class="wsRoundedHalf mt-6 pb-9"  >
        <div class="d-flex align-center justify-space-between">
          <h3 v-if="!SM" class="font-weight-medium" style="line-height: 1.2"> {{ $t('ProfileInformation') }}: </h3>
          <h5 v-if="SM" class="font-weight-medium" style="line-height: 1.2"> {{ $t('ProfileInformation') }}: </h5>

        </div>

        <sideBarUserAvatar class="mt-6" v-if="SM"  />

        <v-row class="mt-6">
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <ws-text-field   v-model="entityData.firstname"
                            outlined
                            dense
                            hide-details
                             :label="$t('Firstname')"
                            :placeholder="$t('Firstname')"/>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <ws-text-field   v-model="entityData.lastname"
                            outlined
                            dense
                            hide-details
                            :label="$t('Lastname')"
                            :placeholder="$t('Lastname')"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <ws-date-picker v-model="entityData.birthday" :label=" $t('Birthday')" :placeholder="$t('Birthday')" />

          </v-col>
          <v-col>
            <ws-select :label="$t('DefaultLanguage')" :placeholder="$t('DefaultLanguage')" :items="languagesSelect" v-model="entityData.language"></ws-select>
          </v-col>
        </v-row>

        <h3 v-if="customFields.length > 0" class="font-weight-medium mt-6"> {{ $t('AdditionalInfo') }}: </h3>
        <v-row class="mt-5">
          <template v-for="item in customFields " >
            <v-col cols="6" :key="item.id + 'field'">
              <div v-if="['link','text'].includes(item.type) " >
                <ws-text-field
                    :prepend-inner-icon="getIcon(item.type)"
                    v-model="entityData.custom_fields[item.id]"
                    :label="item.name"

                />
              </div>
              <div v-if="item.type === 'select'" :key="item.id">
                <ws-select
                    :prepend-inner-icon="getIcon(item.type)"
                    v-model="entityData.custom_fields[item.id]"
                    :items="item.items"
                    :label="item.name"
                    :autocomplete="item.items.length > 7"

                />
              </div>
              <div v-if="item.type === 'boolean'" :key="item.id">
                <ws-switch
                    v-model="entityData.custom_fields[item.id]"
                    :label="item.name"
                />
              </div>
            </v-col>
          </template>
        </v-row>

        <div v-if="SM" class="pb-6">
          <ws-button  outlined :color="wsACCENT" block @click="displayChangePassword = true"  >{{ $t('ChangePassword') }}</ws-button>
        </div>

      </v-sheet>
    </template>
    <template #side>
      <sideBarUserAvatar />

      <v-btn block @click="displayChangePassword = true" outlined class="noCaps mb-3">{{ $t('ChangePassword') }}</v-btn>
    <template v-if="$store.state.business.selectedBusiness.telegramAlias">
      <div class="mt-2 d-flex  align-center justify-space-between">
        <h4>Інтеграції</h4>
        <v-btn icon @click="showIntegrations = !showIntegrations">
          <v-icon  >mdi-chevron-down</v-icon>
        </v-btn>
      </div>
      <v-expand-transition>
        <div v-if="showIntegrations">

          <div class="d-flex align-center justify-space-between">
            <h5 class="d-flex align-center">
              <!--              <v-icon class="mr-3">mdi-send</v-icon>-->
              Telegram
            </h5>
            <v-btn v-if="!entityData.telegram" small outlined text class="noCaps"  target="_blank" :href="`https://t.me/${$store.state.business.selectedBusiness.telegramAlias}`" >
              Telegram Bot
            </v-btn>
            <div v-else>
              <v-btn @click="unlinkTelegram" icon class="noCaps">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
              <v-chip small outlined dark>
                <v-icon small class="mr-1">
                  mdi-check
                </v-icon>
                <h5>Під'єднано</h5>
              </v-chip>
            </div>
          </div>
          <h5 class="d-flex align-center mt-6 font-weight-medium font-italic text-center">
            <span>{{$t('TelegramIntegrationText')}} ({{entityData.link_key}})</span>
          </h5>

        </div>
      </v-expand-transition>
    </template>


    </template>

    <template #dialog>

      <auth-password-change-dialog
          v-if="displayChangePassword"
          v-model="displayChangePassword"
      />

    </template>

  </dash-page>
</template>

<script>
import sideBarUserAvatar from "@/components/pages/dashboard/UI/sidebar/sideBarUserAvatar";
import {mapActions, mapState} from "vuex";
import AuthPasswordChangeDialog from "@/components/auth/authPasswordChangeDialog";

export default {
  name: "DashProfile",
  props : ['user_id'],
  components : {
    AuthPasswordChangeDialog,
    sideBarUserAvatar
  },
  data() {
    return {
      entityData : {},
      customFields : [],

      displayChangePassword : false,

      loading : false,

      showSecurity     : false,
      showIntegrations : true,
      languagesSelect : [
        { text : 'UA' , value : 'ua' },
        { text : 'RU' , value : 'ru' },
        { text : 'EN' , value : 'en' }
      ],

      //profile
      user : {
        id          : null,
        email       : null,
        firstname   : null,
        lastname    : null,
        phone       : null,
        birthday    : null,
        registered  : null,
        telegram    : null,
        link_key    : null,
        locations   : []
      },
      errorLastname : [],
      errorFirstname : [],


    }
  },
  computed : {
    ...mapState('auth',['accessToken']),
    ...mapState('notifications',['notifications']),

    telegramBotLink(){
      let link = 'https://t.me/finetrading_bot'

      if ( this.$store.state.business.selectedBusiness.telegramAlias ) {
        link = this.$store.state.business.selectedBusiness.telegramAlias
      }
      if ( !this.$store.state.domain && !this.$route.params.alias ) {
        link = 'https://t.me/finetrading_bot'
      }

      return link
    },

    novaPoshta() {
      var locations = []

      if ( this.user.locations.length > 0) {
        locations = this.user.locations.filter( el => el.type === 'nova_poshta')
      }


      return locations
    },
    addresses() {
      var locations = []

      if ( this.user.locations.length > 0) {
        locations = this.user.locations.filter( el => el.type === 'address')
      }

      return locations
    }
  },
  watch: {
    notifications(){
      var notify = this.notifications[this.notifications.length-1]

      if (notify.type === 'link_telegram') {
        this.user.telegram = true
      }
    }
  },
  methods: {
    ...mapActions('user',['GET_PROFILE','EDIT_PROFILE','UNLINK_TELEGRAM']),

    async saveChanges() {
      let result = await this.EDIT_PROFILE(this.entityData)
      if (!result) { return this.notify(this.$t('NetworkError')) }
      this.$store.state.auth.userName = this.entityData.lastname + ' ' + this.entityData.firstname
      this.$store.state.auth.userLang = this.entityData.language
      this.notify(this.$t('ChangesSaved'))
    },
    unlinkTelegram() {

      this.loading = true
      this.UNLINK_TELEGRAM().then((out)=>{
        if ( out.result) {
          this.$store.state.auth.user.telegram = false
          this.entityData.telegram = null
          this.notify("Телеграм акаунт від'єднано")

        }
        this.loading = false
      }).catch(()=> { this.loadinng = false })
    },

    // technical
    getIcon(type) {
      let icons = {
        select : 'mdi-form-select',
        text : 'mdi-form-textbox',
        boolean : 'mdi-order-bool-ascending',
        link : 'mdi-link-variant'
      }
      return icons[type] ? icons[type] : 'mdi-form-textbox'
    }


  },
  async mounted() {
    this.entityData = await this.GET_PROFILE()
    this.customFields = this.entityData.custom_fields_select || []
  }
}
</script>

<style scoped>
.form {
  margin : -25px !important;
}
.documents {
  border : 1px solid #dddddd;
  text-align: center;
  margin-bottom: 12px;
}
.uploaded {
  border-color : #cdeac1;
}
</style>
